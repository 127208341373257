// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App {
    text-align: center;
    padding: 20px;
  }
  
.button-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
}
  
button {
    padding: 15px 30px;
    font-size: 18px;
    background-color: #007BFF;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    width: 200px;
}
  
button:hover {
    background-color: #0056b3;
}
  
a {
    text-decoration: none;
}
  
.photoPreview {
    margin-top: 20px;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    object-fit: cover;
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,aAAa;EACf;;AAEF;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,SAAS;AACb;;AAEA;IACI,kBAAkB;IAClB,eAAe;IACf,yBAAyB;IACzB,YAAY;IACZ,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,sCAAsC;IACtC,YAAY;AAChB;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,qBAAqB;AACzB;;AAEA;IACI,gBAAgB;IAChB,YAAY;IACZ,aAAa;IACb,kBAAkB;IAClB,iBAAiB;AACrB","sourcesContent":[".App {\n    text-align: center;\n    padding: 20px;\n  }\n  \n.button-container {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    gap: 15px;\n}\n  \nbutton {\n    padding: 15px 30px;\n    font-size: 18px;\n    background-color: #007BFF;\n    color: white;\n    border: none;\n    border-radius: 5px;\n    cursor: pointer;\n    transition: background-color 0.3s ease;\n    width: 200px;\n}\n  \nbutton:hover {\n    background-color: #0056b3;\n}\n  \na {\n    text-decoration: none;\n}\n  \n.photoPreview {\n    margin-top: 20px;\n    width: 100px;\n    height: 100px;\n    border-radius: 50%;\n    object-fit: cover;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
